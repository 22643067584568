ThemeJS.Media_Queries = ( function ( $ ) {

    let breakpoints_obj = ThemeJS.Variables.Screen_Sizes;

    // JS objects cannot be relied on for ordering
    // rebuild into array of objects and sort
    let breakpoints = [];
    $.each( breakpoints_obj, function ( size, px ) {
        breakpoints.push({ size, px });
    });
    breakpoints.sort( function( a, b ) {
        return a.px - b.px;
    });

    let queries = {};
    $.each( breakpoints, function ( index, obj ) {
        queries[ obj.size ] = window.matchMedia( `( min-width: ${ obj.px }px )` );
        queries[ obj.size ].addListener( breakpoint_changed );
    });

    let previous_size = null;
    let current_size;
    calculate_current_size();
    function calculate_current_size () {
        let max_matched_size = 'xs';
        $.each( queries, function ( size, query ) {
            if ( query.matches ) {
                max_matched_size = size;
                return;
            }
            return false;
        });
        current_size = max_matched_size;
    }

    function breakpoint_changed () {
        previous_size = current_size;
        calculate_current_size();
        $( window ).trigger( 'breakpoint_changed', [ current_size, previous_size ] );
    }

    return {
        get_current_size: function () {
            return current_size;
        },
        is_at_least: function ( size ) {
            return queries[ size ].matches;
        },
    };

})( jQuery );