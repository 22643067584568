(function ($) {
  if ( !$ ('#product-image').length ){
    return;
  } 
  initProductZoom();  
}) (jQuery);

function initProductZoom(){
  jQuery('#product-image').ezPlus ({
    zoomWindowWidth: 400,
    zoomWindowHeight: 400,
    scrollZoom: true,
    lensSize: 400,
    lensFadeIn: 400,
    zoomWindowFadeIn: 400,
    lensFadeOut: 200,
    zoomWindowFadeOut: 400,
    cursor: 'crosshair'
  });
}