( function( $ ){

    $( document ).on( 'click', '.accordion-trigger', function(){
        if( $( this ).next( '.accordion-section' ).is( ':visible' ) ){
            $( this ).toggleClass( 'open' );
            $( this ).next( '.accordion-section' ).hide();
            return;
        }
        $( this ).closest( '.accordion-accessible' ).find( '.accordion-section' ).hide();
        $( this ).closest( '.accordion-accessible' ).find( '.accordion-trigger' ).removeClass( 'open' );
        $( this ).next( '.accordion-section' ).show();
        $( this ).addClass( 'open' );
    });

})( jQuery );