let Products_Api = (function( $ ){

    const base_url = ThemeJS.Variables.Api;
    const site_url = ThemeJS.Variables.siteUrl;

    // Get Products
    const get_products = ( page ) => {
        let filters = get_filters();
        filters.page = page;
        update_url();
        return $.get( base_url + '/products', filters );
    }

    const get_filters = () => {
        let filters = {};
        $( '.product-filters select' ).each( function(){
            if( 'all' !== $( this ).val() ){
                filters[ $( this ).attr( 'name' ) ] = $( this ).val();
            }
        });
        $( '.product-filters input' ).each( function(){
            filters[ $( this ).attr( 'name' ) ] = $( this ).is( ':checked' );
        });
        return filters;
    }

    const update_url = () => {
        let filter_string = site_url + '/products';
        $( '.product-filters select' ).each( function(){
            filter_string += '/' + $( this ).val();
        });
        $( '.product-filters input' ).each( function(){
            if( $( this ).is( ':checked' ) ){
                filter_string += '/new';
            }
        });
        window.history.pushState( { filters: filter_string }, 'Dainolite - Bring your lighting visions to life', filter_string );
    }

    return {
        get_products
    }

})( jQuery );