( function ( $ ) {

    // smooth-scroll / toggle modal on click of id href
    $( document ).on( 'click', 'a[href^="#"]', function ( e ) {
        let $target = $( $(this).attr('href') );
        if ( $target.length ) {
            e.preventDefault();
            if ( $target.hasClass('modal') ) {
                $target.modal('toggle');
                return;
            }
            $('html, body').animate(
                { scrollTop: $target.offset().top },
                500,
                function() {
                    $target.focus();
                    if ( $target.is(":focus") ) {
                        return false;
                    } else {
                        $target.attr('tabindex','-1');
                        $target.focus();
                    }
                }
            );
        }
    });

})( jQuery );