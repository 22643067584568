( function( $ ){

    let menu_trap        = focusTrap.createFocusTrap( '.header-menu' );

    $( '.mobile-menu-open' ).on( 'click', () => {
        $( '.header-menu' ).addClass( 'open' );
        $( '#menu-header-menu' ).show();
        menu_trap.activate();
        
    });

    $( '.mobile-menu-close' ).on( 'click', () => {
        $( '.header-menu' ).removeClass( 'open' );
        $( '#menu-header-menu' ).hide();
        menu_trap.deactivate();
    });

    // Submenus
    const set_submenu_position = () => {
        let header_height = $( '.header' ).height();
        $( '.sub-menu-wrapper' ).css({ top: header_height + 'px' });
        $( '.sub-menu-wrapper' ).each( function(){
            let $parent     = $( this ).closest( '.menu-item' );
            let left        = $parent.offset().left + 25;
            $( this ).find( '.sub-menu' ).css({left: left + 'px'});
        });
    }
    set_submenu_position();
    $( window ).resize( set_submenu_position );

})( jQuery );
