jQuery( document ).ready( function( $ ){

    $( '.product-specs-expand' ).on( 'click', function(){
        let is_open             = $( this ).hasClass( 'active' );
        let $specs_container    = $( this ).prev( '.product-specifications' );
        let $inner_window       = $specs_container.find( '.product-specifications-window' );
        let height              = is_open ? 240 : $inner_window.height() + 20;
        $specs_container.animate({ 'height': height + 'px' });
        $( this ).toggleClass( 'active' );
    });

});