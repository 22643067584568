jQuery( document ).ready( function( $ ){

    const set_favourite = ( product_id, set ) => {
        console.log( 'set favourite', product_id, set );
        let data = {
            action: 'set_product_favourite',
            product_id: product_id,
            set_favourite: set
        };
        $.post( ThemeJS.Variables.Ajax.url, data, (res) => {
            console.log( res );
        });
    }

    $( document ).on( 'click', '.favourite-toggle', function( e ){
        e.preventDefault();
        e.stopPropagation();
        let product_id      = $( this ).attr( 'data-product' );
        let set             = !$( this ).hasClass( 'is-favourite' );
        set_favourite( product_id, set );
        $( this ).toggleClass( 'is-favourite' ).toggleClass( 'is-not-favourite' );
    });

});