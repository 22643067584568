jQuery( document ).ready( function( $ ){

    $( '.projects-slider-container' ).slick({
        infinite: true,
        dots: false,
        rows: 0,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span><span class="fas fa-chevron-left"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span><span class="fas fa-chevron-right"></span></button>',
        responsive:[
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

});