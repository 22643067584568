jQuery( document ).ready( function( $ ){

    $( '.gallery-slider' ).slick({
        infinite: true,
        dots: false,
        rows: 0,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span><span class="fas fa-chevron-left"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span><span class="fas fa-chevron-right"></span></button>',
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive:[
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });

    $( '.slide .product-thumb-container' ).on( 'click', function(){
        $( '#product-image' ).attr( 'src', $( this ).find( 'img' ).attr( 'src' ) );
        initProductZoom();
    });

});