jQuery( document ).ready( function( $ ){

    if( ! $( '.product-filters' ).length ){
        return;
    }

    let request_running = false;
    const run_filters = ( page ) => {
        if( request_running ){
            return;
        }
        request_running = true;
        Products_Api.get_products( page ).done( result => {
            has_products = result.has_products;
            if( result.success ){
                if( 1 == page ){
                    $( '#products-gallery-outlet' ).html( result.markup );
                    $( 'html, body' ).animate({
                        scrollTop: $( '#products-gallery-outlet' ).offset().top - 100
                    }, 500, () => {
                        request_running = false;
                    });
                }
                else{
                    $( '#products-gallery-outlet' ).append( result.markup );
                    request_running = false;
                }
            }
        }).fail( error => {
            console.log( error )
        });
    }

    const clear_filters = () => {
        $( '.product-filters select' ).each( function(){
            $( this ).val( 'all' ).trigger( 'change' );
        });
        $( '.product-filters input:checked' ).each( function(){
            $( this ).prop( 'checked', false );
        })
    }

    $( '#submit-filters' ).on( 'click', () => { 
        page_request = 1;
        run_filters( page_request ); 
    });

    $( '#clear-filters' ).on( 'click', () => {
        clear_filters();
        run_filters(1);
    });

    let page_request = 1;
    let has_products = true;
    $( document ).on( 'scroll', () => {
        if( request_running || ! has_products ){
            return;
        }
        let scrollpos       = $( window ).scrollTop();
        let window_height   = $( window ).height();
        let gallery_top     = $( '.products-gallery' ).position().top;
        let gallery_height  = $( '.products-gallery' ).height()
        if( scrollpos + window_height > gallery_top + gallery_height - 300 ){
            page_request++;
            run_filters( page_request );
        }
    });
    run_filters( page_request );

    //Init Select2
    $( '.product-filter-wrapper select' ).select2({
        minimumResultsForSearch: -1,
        closeOnSelect: true,
        allowClear: false,
    });

});